<template>
  <div class="chart">
    <Head :recruit="false"></Head>
      <iframe width="100%" class="main" :src="'https://webchat.7moor.com/pcwebchat.html?accessId=78a5f320-fd2d-11ec-8e8c-ddf4e464ca8d&urlTitle=丹棱职聘&fromUrl=&language=zh-CN&otherParams='+ this.m7CardInfo +'&clientId=' + userInfo.userId + '&unionAccessId=undefined&customField=&showAnnexUrl=false&priority=false&hideImgUpload=false&hideFileUpload=false'" frameborder="0"></iframe>
    <Footer></Footer>
  </div>
</template>
<script>
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Index_JobSeeker",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      userInfo: {
        loginDO: {}
      },
      m7CardInfo:''
    };
  },
  mounted() {
    const userInfo = window.sessionStorage.getItem('userInfo')
    const m7CardInfo =  window.sessionStorage.getItem('m7CardInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
    if(m7CardInfo) {
      this.m7CardInfo = encodeURIComponent('{"cardInfo": '+ m7CardInfo +', "nickName": "'+(this.userInfo.loginDO.tel+(this.userInfo.userName?'@'+this.userInfo.userName:''))+'"}')
    }
    
    
    // this.createScript('https://webchat.7moor.com/javascripts/7moorInit.js?accessId=78a5f320-fd2d-11ec-8e8c-ddf4e464ca8d&autoShow=false&language=ZHCN')
  },
  methods: {
    createScript(src){
      var head = document.getElementsByTagName("head")[0];
      var js = document.createElement("script");
      js.src = src
      head.appendChild(js);
    }
  },
};
</script>
<style scoped>
.chart {
  width: 100%;
  height: 100%;
}
.chart .main {
  min-height: calc(100% - 278px);
}
</style>
